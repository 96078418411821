import classNames from "classnames";
import type { GetServerSidePropsContext } from "next";
import { getCsrfToken, signIn } from "next-auth/react";
import { useRouter } from "next/router";
import React, { useState, useEffect, useRef } from "react";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";

import { ErrorCode, getSession } from "@calcom/lib/auth";
import { WEBAPP_URL } from "@calcom/lib/constants";
import { getSafeRedirectUrl } from "@calcom/lib/getSafeRedirectUrl";
import { useLocale } from "@calcom/lib/hooks/useLocale";
import { collectPageParameters, telemetryEventTypes, useTelemetry } from "@calcom/lib/telemetry";
import { Alert, Button, EmailField, PasswordField } from "@calcom/ui";

import type { inferSSRProps } from "@lib/types/inferSSRProps";
import type { WithNonceProps } from "@lib/withNonce";
import withNonce from "@lib/withNonce";

import AddToHomescreen from "@components/AddToHomescreen";
import AuthContainer from "@components/ui/AuthContainer";

import { ssrInit } from "@server/lib/ssr";

interface LoginValues {
  email: string;
  password: string;
  csrfToken: string;
}

/**
 * This page is cloned from login.tsx with customizations for JWT based SSO login. It isn't
 * intended to be used for arbitrary login.
 *
 * Given the copy/paste nature of this page from login.tsx, whenever that page is updated
 * from cal.com (a fresh pull) please double check if something needs to be changed here and
 * reconcile if necessary.
 */
export default function SsoLogin({ csrfToken }: inferSSRProps<typeof _getServerSideProps> & WithNonceProps) {
  const { t } = useLocale();
  const router = useRouter();
  const methods = useForm<LoginValues>();
  const formRef = useRef<any>(null);
  const { register, formState } = methods;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSignInDisabled, setIsSignInDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (formRef.current && router.query.email && router.query.token) {
      methods.handleSubmit(onSubmit)();
    } else {
      setErrorMessage("Please sign in from the dashboard");
      setIsSignInDisabled(true);
    }
  }, [router.query.email, router.query.token]);

  const errorMessages: { [key: string]: string } = {
    // [ErrorCode.SecondFactorRequired]: t("2fa_enabled_instructions"),
    // Don't leak information about whether an email is registered or not
    [ErrorCode.IncorrectUsernamePassword]: t("incorrect_username_password"),
    [ErrorCode.IncorrectTwoFactorCode]: `${t("incorrect_2fa_code")} ${t("please_try_again")}`,
    [ErrorCode.InternalServerError]: `${t("something_went_wrong")} ${t("please_try_again_and_contact_us")}`,
    [ErrorCode.ThirdPartyIdentityProviderEnabled]: t("account_created_with_identity_provider"),
  };

  const telemetry = useTelemetry();

  let callbackUrl = typeof router.query?.callbackUrl === "string" ? router.query.callbackUrl : "";

  if (/"\//.test(callbackUrl)) callbackUrl = callbackUrl.substring(1);

  // If not absolute URL, make it absolute
  if (!/^https?:\/\//.test(callbackUrl)) {
    callbackUrl = `${WEBAPP_URL}/${callbackUrl}`;
  }

  const safeCallbackUrl = getSafeRedirectUrl(callbackUrl);

  callbackUrl = safeCallbackUrl || "";

  const onSubmit: SubmitHandler<LoginValues> = async (values, e) => {
    if (e) e.preventDefault();
    setErrorMessage(null);
    telemetry.event(telemetryEventTypes.login, collectPageParameters());

    const res = await signIn<"credentials">("jwt_auth", {
      ...values,
      callbackUrl,
      redirect: false,
    });
    if (!res) setErrorMessage(errorMessages[ErrorCode.InternalServerError]);
    // we're logged in! let's do a hard refresh to the desired url
    else if (!res.error) router.push(callbackUrl);
    // fallback if error not found
    else setErrorMessage(errorMessages[res.error] || t("something_went_wrong"));
  };

  return (
    <>
      <AuthContainer title={t("login")} description={t("login")}>
        <FormProvider {...methods}>
          <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)} data-testid="login-form">
            <div>
              <input defaultValue={csrfToken || undefined} type="hidden" hidden {...register("csrfToken")} />
            </div>
            <div className="space-y-6">
              <div className={classNames("space-y-6", { hidden: false })}>
                <EmailField
                  id="email"
                  label={t("email_address")}
                  defaultValue={router.query.email as string}
                  placeholder="john.doe@example.com"
                  required
                  {...register("email")}
                  disabled={isSignInDisabled || errorMessage !== null}
                />
                <div className="relative">
                  <PasswordField
                    id="password"
                    autoComplete="off"
                    required
                    defaultValue={router.query.token as string}
                    className="mb-0"
                    {...register("password")}
                    disabled={isSignInDisabled || errorMessage !== null}
                  />
                </div>
              </div>

              {errorMessage && <Alert severity="error" title={errorMessage} />}
              <Button
                type="submit"
                color="primary"
                disabled={isSignInDisabled || errorMessage !== null || formState.isSubmitting}
                className="w-full justify-center">
                {t("sign_in")}
              </Button>
            </div>
          </form>
        </FormProvider>
      </AuthContainer>
      <AddToHomescreen />
    </>
  );
}

// TODO: Once we understand how to retrieve prop types automatically from getServerSideProps, remove this temporary variable
const _getServerSideProps = async function getServerSideProps(context: GetServerSidePropsContext) {
  const { req } = context;
  const session = await getSession({ req });
  const ssr = await ssrInit(context);

  if (session) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  return {
    props: {
      csrfToken: await getCsrfToken(context),
      trpcState: ssr.dehydrate(),
    },
  };
};

export const getServerSideProps = withNonce(_getServerSideProps);
